
  import { computed, defineComponent, ref, getCurrentInstance } from "vue"
  import { CollectionResourceDoc } from '@/models/jsonapi'
  import { Visor } from "@/models/visors";
  import { BvTableCtxObject } from "bootstrap-vue"
  import TitleActionsNavbar from "@/components/shared/TitleActionsNavbar.vue"
  import { apiDataMorpher, extractRelationshipIds } from "@/utils/apiDataHelper"

  export default defineComponent({
    components: {
      TitleActionsNavbar,
    },
    setup(_) {
      const root = getCurrentInstance().proxy
      const totalItems = computed<number>(() => root.$store.state.visors.pagination.totalItems)
      const getItems = async(ctx: BvTableCtxObject) => {
        const queryParams = {
          'page[number]': ctx.currentPage,
          'page[size]': ctx.perPage,
          'include': 'household'
        }

        await root.$store.dispatch('visors/load', queryParams)
        return root.$store.state.visors.data
      }

      const columns = ref([

        {
          component: () => import("@/components/shared/table/cells/LinkCell.vue"),
          key: "id",
          title: "Agentur",
          sortable: true,
          options: { hrefFunction: (data: any) => `/visors/${data.item.id}` }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.createdAt",
          title: "Erstellungsdatum",
          sortable: true,
          options: { type: 'date' }
        },
      ])

      const titleActions = ref([
        {
          title: 'Dokument',
          icon: 'plus',
          tooltip: 'Dokument',
          onClick: () => alert('boubou'),
        },
      ])

      return {
        getItems,
        totalItems,
        columns,
        titleActions,
      }
    }
  })
